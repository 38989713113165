import clientHelper from '../helpers/client'
import ui, { 
    clientsGenders
} from '../ui/client'

export default {
    data: () => ({
        clientsGenders
    }),

    methods: {
        ...clientHelper,
        ...ui,
    }
}
