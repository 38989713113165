import './common'
import axios from 'axios'

export default {
    getActivities: (params) => axios.get('/activities', { params }),
    getPeriodicActivities: (params) => axios.get('/periodic/activities', { params }),
    getActivity: (id) => axios.get(`/activities/${id}`),
    getActivitiesFamilies: (params) => axios.get('/activities-families', { params }),
    getLevels: (params) => axios.get('/levels', { params })

}
