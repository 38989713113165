import Vue from 'vue'
import Router from 'vue-router'

import AuthRoutes from './auth'
import PlanningRoutes from './planning'
import TurnstilesRoutes from './turnstiles'
import ClientsRoutes from './clients'
import QuotationRoutes from './quotation'
import SpaRoutes from './spa'
import StatsRoutes from './stats'
import BusinessRoutes from './business'
import LockersRoutes from './lockers'
import ActivitiesRoutes from './activities'
import SetupRoutes from './setup'
import CashregisterWebviewsRoutes from './cashregisterWebviews'
import KiosksRoutes from './kiosks'
import { checkRights, isAuthenticated, setForbidden } from './permissions'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...AuthRoutes,
        ...PlanningRoutes,
        ...TurnstilesRoutes,
        ...ClientsRoutes,
        ...QuotationRoutes,
        ...SpaRoutes,
        ...StatsRoutes,
        ...BusinessRoutes,
        ...LockersRoutes,
        ...ActivitiesRoutes,
        ...SetupRoutes,
        ...CashregisterWebviewsRoutes,
        ...KiosksRoutes
    ]
})

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = 'Streamlor - ' + nearestWithTitle.meta.title
    }

    setForbidden(false)
    // Check auth and perms
    if (checkRights(to.name)) {
        next()
    } else if (!isAuthenticated()) {
        next('/')
    } else {
        setForbidden(true)
        next()
    }
})

export default router
