import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import createPersistedState from 'vuex-persistedstate'

import main from './main'
import params from './params'
import stats from './stats.js'
import turnstiles from './turnstiles.js'
import spa from './spa'
import planning from './planning'
import ws from './ws'
import template from './template'
import lockers from './lockers'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        reducer: (state) => {
            const reduced = JSON.parse(JSON.stringify(state))
            reduced.main.auth.status = 'idle'
            delete reduced.main.products
            reduced.main.accessTimeslots = []

            return (reduced)
          },
        rehydrated (store) {
            // TODO: Make this dynamic per module
            if (typeof store.state.stats.resa.filter.timeScaleBegin === 'string') {
                store.state.stats.resa.filter.timeScaleBegin = moment(store.state.stats.resa.filter.timeScaleBegin)
            }
            if (typeof store.state.stats.resa.filter.timeScaleEnd === 'string') {
                store.state.stats.resa.filter.timeScaleEnd = moment(store.state.stats.resa.filter.timeScaleEnd)
            }
        }
    })],
    modules: {
        main,
        params,
        stats,
        spa,
        planning,
        ws,
        template,
        turnstiles,
        lockers
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {
        resetAll ({ commit }) {
            commit('reset_main')
            commit('reset_params')
            commit('reset_stats')
            commit('reset_stats_spa')
            commit('reset_stats_resa')
            commit('reset_stats_resa_frequentations')
            commit('reset_stats_resa_bilans_periodiques')
            commit('reset_stats_resa_plannings')
            commit('reset_spa')
            commit('reset_ws')
            commit('reset_turnstiles')
            commit('reset_lockers')
        }
    }
})
