import Consts from '../consts'

const clientsGenders = [
    { text: 'M', value: Consts.CLIENTS.GENDERS.MALE, civilite: 'Monsieur', sex: "Homme", icon: 'mdi-gender-male' },
    { text: 'Mme', value: Consts.CLIENTS.GENDERS.FEMALE , civilite: 'Madame', sex: "Femme", icon: 'mdi-gender-female' },
    // { text: '', value: Consts.CLIENTS.GENDERS.UNKNOW , civilite: 'Non précisé', sex: "Non précisé", icon: 'mdi-gender-non-binary' }
]

export default {
    /**
     * @param {string} x Gender code : 'M', 'F', etc.
     */
    clientsGender(x) {
        return clientsGenders.find(elem => elem.value == x)
    }
}

export { 
    clientsGenders
}