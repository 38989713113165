import { mapGetters } from 'vuex'
import EventBus from '~/eventbus'

const WSFrameTypeKeepAlive = (-1)
const WSFrameTypeError = (0)
const WSFrameTypeSubscribe = (1)
const WSFrameTypeNotify = (2)
const WSFrameTypeRequestAuth = (3)
const WSFrameTypeResponseAuth = (4)
const WSFrameTypeUpdateTurnstile = (5)
const WSFrameTypeUnsubscribe = (6)

export default {
    data () {
        return {
            wsState: {
                Authenticated: false
            }
        }
    },

    computed: {
        ...mapGetters(['getSubscribersFor']),

        ws_IsConnected () {
            return this.$socket.readyState === 1
        },

        ws_IsAuthenticated () {
            return this.wsState.Authenticated
        }
    },

    methods: {
        _ws_Register () {
            this.$options.sockets.onmessage = this._ws_OnMessage
        },

        _ws_Unregister () {
            delete this.$options.sockets.onmessage
        },

        _ws_OnMessage (data) {
            const lines = data.data.split('\n')

            for (let i = 0; i < lines.length; ++i) {
                const json = JSON.parse(lines[i])

                switch (json.Type) {
                case WSFrameTypeError:
                    if (this.ws_cb_onError) {
                        this.ws_cb_onError(json.Data)
                    }
                    break

                case WSFrameTypeResponseAuth:
                    if (this.ws_cb_ResponseAuth) {
                        this.ws_cb_ResponseAuth(json.Data)
                    }
                    break

                case WSFrameTypeSubscribe:
                    // ignore
                    break

                case WSFrameTypeNotify:
                    if (this.ws_cb_onNotify) {
                        this.ws_cb_onNotify(json.Data)
                    }
                    break

                case WSFrameTypeUpdateTurnstile:
                    // ignore
                    break

                case WSFrameTypeUnsubscribe:
                    // ignore
                    break

                default:
                    // eslint-disable-next-line
                        console.warn("Unhandled message type: ", json.Type);
                    break
                }
            }
        },

        _ws_sendWithRetry (type, data, skipRetry) {
            if (this.$socket.readyState === 1) {
                this.$socket.sendObj({
                    Type: type,
                    Data: data
                })
            } else if (!skipRetry) {
                // Not connected yet, retry in a bit
                console.warn('Not connected yet, postponing sending message', type)
                setTimeout(() => {
                    this._ws_sendWithRetry(type, data)
                }, 100)
            }
        },

        ws_KeepAlive () {
            this._ws_sendWithRetry(WSFrameTypeKeepAlive, null, true)
        },

        ws_Authenticate (token) {
            this._ws_sendWithRetry(WSFrameTypeRequestAuth, { Token: token })
        },

        ws_Subscribe (channel) {
            this._ws_sendWithRetry(WSFrameTypeSubscribe, { Resource: channel })
        },

        ws_Unsubscribe (channel) {
            this._ws_sendWithRetry(WSFrameTypeUnsubscribe, { Resource: channel })
        },

        ws_On (channel, callbacks) {
            if (this.$socket.readyState === 1) {
                if (this.getSubscribersFor(channel) === 0) {
                    this.ws_Subscribe(channel)
                }
                this.$store.dispatch('subscribeTo', channel)
                EventBus.$on(channel, callbacks)
            } else {
                // Not connected yet, retry in a bit
                console.warn('Not connected yet (' + this.$socket.readyState + '), postponing ws_On')
                setTimeout(() => {
                    this.ws_On(channel, callbacks)
                }, 100)
            }
        },

        ws_Off (channel, callbacks) {
            this.$store.dispatch('unsubscribeFrom', channel)
            EventBus.$off(channel, callbacks)
            if (this.getSubscribersFor(channel) === 0) {
                this.ws_Unsubscribe(channel)
            }
        },

        ws_UpdateTurnstile (turnstile) {
            this._ws_sendWithRetry(WSFrameTypeUpdateTurnstile, turnstile)
        }
    }
}
