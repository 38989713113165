
var self = {
  getPaginationFromDataTableOptions(o) {
    return {
      Offset: (o.page - 1) * o.itemsPerPage,
      Limit: o.itemsPerPage,
      Sort: o.sortBy.map((field, fieldIdx) => (o.sortDesc[fieldIdx] ? "-" : "") + field),
    };
  },

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },

  equals(a, b) {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== "object" && typeof b !== "object")) return a === b;
    if (a.prototype !== b.prototype) return false;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every((k) => this.equals(a[k], b[k]));
  },

  newUUID(){
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  },

  dataURLToBlob (dataURL) {
    const base64Data = dataURL.split(',')[1]
    const mimeType = dataURL.split(';')[0].split(':')[1]
    const binaryData = atob(base64Data)
    const arrayBuffer = new ArrayBuffer(binaryData.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i)
    }

    const blob = new Blob([arrayBuffer], { type: mimeType })

    return blob
},
  
  fileToBase64(file){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    })
  }
}

export var isInteger = v => (Number.isInteger(parseFloat(v)))
export var isPositive = v => (parseFloat(v) > 0)
export var isPositiveOrZero = v => (parseFloat(v) >= 0)
export var isNumeric = v => (!isNaN(v) && !isNaN(parseFloat(v)))
export var isValidEmail = v => /.+@.+\..+/.test(v)
export var isUUID = v => {
  var pattern = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$')
  return pattern.test(v)
}

export default self