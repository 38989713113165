<template>
    <v-app v-cloak>
        <v-navigation-drawer persistent clipped :mini-variant="drawer" v-if="!isDashboardLayoutDisabled" stateless
                             fixed app class="grey lighten-4 no-print" :permanent="navigationDrawerState && !isDashboardLayoutDisabled">
            <v-list class="main-nav">
                <v-list-item v-for="(item, i) in navDrawerItems" :key="i" :to="item.route" :title="item.title" color="primary">
                    <v-list-item-action>
                        <v-badge bottom dot color="red" :value="item.alert && item.alert > 0">
                            <v-icon v-html="item.icon"></v-icon>
                        </v-badge>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app class="elevation-0 no-print" style="background: #267CBC" dark flat clipped-left v-if="!isDashboardLayoutDisabled" dense>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title v-html="title" layout="row" layout-align="start center" />

            <v-spacer />

            <v-toolbar-items>
                <v-divider vertical />
                <!-- Voucher scan button -->
                <v-tooltip bottom v-if="hasRight('admin:business:voucher')">
                    <template v-slot:activator="{ on }">
                        <v-btn text @click="voucherScanDialog = true" v-on="on">
                            <v-icon>mdi-ticket-percent</v-icon>
                        </v-btn>
                    </template>

                    <span>Scanner un e-billet/bon cadeau</span>
                </v-tooltip>
                <v-divider vertical />

                <!-- VCR -->
                <v-tooltip bottom v-if="hasVcr">
                    <template v-slot:activator="{ on }">
                        <v-btn text @click="goToVCR" v-on="on">
                            <v-icon>mdi-cash-register</v-icon>
                        </v-btn>
                    </template>

                    <span>Utiliser la caisse virtuelle</span>
                </v-tooltip>
                <v-divider vertical v-if="hasVcr" />

                <!-- Dims switch button -->
                <v-tooltip bottom v-if="hasDimsBackoffice">
                    <template v-slot:activator="{ on }">
                        <v-btn text @click="goToDims" v-on="on">
                            <v-icon>mdi-bug</v-icon>
                        </v-btn>
                    </template>

                    <span>Basculer vers Dims</span>
                </v-tooltip>
                <v-divider vertical v-if="hasDimsBackoffice" />

                <!-- Odeon button -->
                <v-menu v-model="odeonPopup.visible" bottom offset-y :close-on-content-click="false" max-width="800px" eager v-if="authUser && authStatus === 'success'">
                    <template #activator="{ on: onMenu }">
                        <v-tooltip bottom v-on="onMenu">
                            <template v-slot:activator="{ on }">
                                <v-btn text v-on="on" @click="odeonPopup.visible = true">
                                    <v-badge overlap dot color="red" :value="odeonPopup.unread !== 0">
                                        <v-icon>mdi-lifebuoy</v-icon>
                                    </v-badge>
                                </v-btn>
                            </template>

                            <span>Aide / Support</span>
                        </v-tooltip>
                    </template>

                    <odeon-popup @unread="onUnreadCount" />
                </v-menu>
                <v-divider vertical />
            </v-toolbar-items>

            <v-menu bottom offset-y v-if="authUser">
                <template v-slot:activator="{ on }">
                    <v-btn text slot="activator" style="height: 64px" v-on="on">
                        <div>
                            {{ authUser.FirstName }} {{ authUser.LastName }}
                            <v-avatar color="teal" class="ml-2" size="32"><span class="white--text">{{ userInitials }}</span></v-avatar>
                        </div>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item :to="{name: 'profil'}">
                            <v-list-item-action><v-icon>mdi-account-details</v-icon></v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Profil</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="logout">
                            <v-list-item-action><v-icon>mdi-logout</v-icon></v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Déconnexion</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>

            <v-divider vertical v-if="wvEnabled()"></v-divider>
            <v-btn text v-if="wvEnabled()" @click="wvClose" style="height: 64px">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <transition name="fade" mode="out-in">
                <forbidden-page v-if="forbidden"></forbidden-page>
                <router-view v-else/>
            </transition>
        </v-main>

        <v-footer v-if="!isDashboardLayoutDisabled" app inset class="px-3 no-print">
            <div layout="row" layout-align="start center" class="text-overline">
                <a href="https://www.streamlor.io" target="_blank" rel="noopener noreferrer" layout="row" layout-align="center center">
                    <img src="/streamlor-h15.png" height="15" class="mx-2">
                </a>
                <span class="mx-2">&bull;</span>
                <span>Copyright &copy; 2018-{{ new Date().getFullYear() }}</span>
                <span class="mx-2">&bull;</span>
                <div class="text-overline">Version {{ version }}</div>
                <span class="ml-2">&bull;</span>
            </div>
        </v-footer>

        <vue-snotify></vue-snotify>

        <v-dialog v-model="voucherScanDialog" width="1000px">
            <voucher-scan @validated="voucherScanDialog = false"></voucher-scan>
        </v-dialog>
    </v-app>
</template>

<script>
import MainMixin from '@/mixins/main.js'
import WebviewMixin from '~/mixins/webview'
import WsMixin from '~/mixins/ws'
import ApiAuth from '~/api/auth'
import EventBus from '~/eventbus'
import VoucherScan from '~/views/global/VoucherScan.vue'
import { mapGetters } from 'vuex'
import Consts from 'neptune/consts'
import OdeonPopup from '@/components/odeon/OdeonPopup'
import ForbiddenPage from '@/views/Forbidden.vue'

export default {
    components: { ForbiddenPage, OdeonPopup, VoucherScan },

    mixins: [MainMixin, WebviewMixin, WsMixin],

    name: 'App',
    data () {
        return {
            drawer: true,
            voucherScanDialog: false,
            version: '',
            changelogDialog: false,
            odeonPopup: {
                visible: false,
                unread: 0
            },
            barcode: {
                value: '',
                reading: false
            }
        }
    },

    created () {
        // Connect websocket
        this.$connect()
    },

    mounted () {
        this.$store.dispatch('getModules')
        this.$store.dispatch('resetSubscribers')

        if (this.ws_IsConnected && this.authUser) {
            // If we are already connected, send hello directly, otherwise wait for the socket to be open
            this.ws_Authenticate(this.authToken)
        }

        this._ws_Register()
        this.$options.sockets.onopen = () => {
            this.ws_Authenticate(this.authToken)
        }

        this.$options.sockets.onclose = () => {
            setTimeout(() => {
                if (!this.$socket || this.$socket.readyState !== 1) {
                    this.$nextTick(() => {
                        this.$connect()

                        setTimeout(() => {
                            // Retry connection after some timeout
                            if (!this.$socket || this.$socket.readyState !== 1) {
                                this.$disconnect()
                            }
                        }, 1000)
                    })
                }
            }, 1000)
        }

        EventBus.$on('wv-force-route', (route) => {
            if (typeof route === 'object') {
                this.loadWebviewForcedRoute(route.route, route.opts)
            } else {
                this.loadWebviewForcedRoute(route)
            }
        })

        this.$options.sockets.onerror = (err) => {
            console.error('WebSocket error:', err)
        }

        // Start the WS KeepAlive ping every 60 seconds
        setInterval(() => {
            this.ws_KeepAlive()
        }, 60000)

        EventBus.$on('toast-message', ({ type, message }) => {
            this.$snotify[type](message)
        })

        if (this.hasRight('admin:lockers') && this.modules.lockers) {
            this.ws_On(
                Consts.WS_RESOURCE_LOCKER_INCIDENTS_NEW,
                this.onNewLockerIncident
            )
        }

        // barcode scanner
        document.addEventListener('keypress', e => {
            // usually scanners throw an 'Enter' key at the end of read
            if (e.keyCode === 13) {
                if (this.barcode.value.length > 10) {
                    EventBus.$emit('barcode-scanned', this.barcode.value)
                    this.barcode.value = ''
                }
            } else {
                this.barcode.value += e.key // while this is not an 'enter' it stores the every key
            }

            if (!this.barcode.reading) {
                this.barcode.reading = true
                setTimeout(() => {
                    this.barcode.value = ''
                    this.barcode.reading = false
                }, 200)
            }
        })

        // put in next tick because we suspect cashregister webview inject script not to be ready yet so wvEnabled() can return false
        setTimeout(() => {
            if (this.isLoggedIn && !this.wvEnabled()) {
                ApiAuth.checkAuthToken(this.authToken).then(() => {
                    this.$store.commit('set_auth_status', 'success')
                    this.$store.dispatch('getGlobalParams')
                    if (window.location.pathname === '/') {
                        this.getDefaultRoute().then(route => {
                            this.$router.push({ name: route })
                        })
                    }
                })
            } else if (this.wvEnabled()) {
                if (this.$route.name !== 'autologin') {
                    this.wvReady()
                }
            }
        }, 500)
    },

    destroyed () {
        if (this.hasRight('admin:lockers') && this.modules.lockers) {
            this.ws_Off(
                Consts.WS_RESOURCE_LOCKER_INCIDENTS_NEW,
                this.onNewLockerIncident
            )
        }
    },

    watch: {
        authUser: {
            deep: true,
            handler () {
                if (this.ws_IsConnected && !this.ws_IsAuthenticated) {
                    this.ws_Authenticate(this.authToken)
                }
            }
        }
    },

    computed: {
        ...mapGetters(['authToken',
                       'authStatus',
                       'authUser',
                       'dimsBackofficeUrl',
                       'forbidden',
                       'hasRight',
                       'isLoggedIn',
                       'modules',
                       'navigationDrawerState',
                       'newIncidentsCounter',
                       'vcrUrl'
        ]),

        hasDimsBackoffice () {
            return !!this.dimsBackofficeUrl
        },

        hasVcr () {
            return !!this.vcrUrl
        },

        userInitials () {
            return this.authUser.FirstName.substr(0, 1) + this.authUser.LastName.substr(0, 1)
        },

        title () {
            let out = ''
            this.$route.matched.forEach((v, idx) => {
                if (idx > 0) {
                    out += ' <i class="mdi mdi-chevron-right mx-2"></i> '
                }
                out += v.meta.title
            })
            return out
        },

        isDashboardLayoutDisabled () {
            return !!this.$route.meta.disableDashboardLayout
        },

        navDrawerItems () {
            const out = []

            if (!this.modules) {
                return out
            }

            if (this.hasRight('admin:planning') && this.modules.planning) {
                out.push({
                    route: { name: 'planning-index' },
                    icon: 'mdi-calendar-month',
                    title: 'Planning',
                    alert: false
                })
            }
            if (this.hasRight('admin:ctm') && this.modules.ctm) {
                out.push({
                    route: { name: 'turnstiles-index' },
                    icon: 'mdi-turnstile',
                    title: 'Contrôle d\'accès',
                    alert: false
                })
            }
            if (this.hasRight('admin:clients') && this.modules.clients) {
                out.push({
                    route: { name: 'clients-index' },
                    icon: 'mdi-account-multiple',
                    title: 'Clients',
                    alert: false
                })
            }
            if (this.hasRight('admin:business') && this.modules.business) {
                let routeName = 'business-index'
                if (this.hasRight('admin:business:export')) {
                    routeName = 'business-exports-financial'
                } else if (this.hasRight('admin:business:voucher')) {
                    routeName = 'business-gift-manager'
                } else if (this.hasRight('admin:business:voucher-sheet')) {
                    routeName = 'business-gift-manager-plancha'
                } else if (this.hasRight('admin:business:discount:template')) {
                    routeName = 'business-discounts-manager-templates'
                } else if (this.hasRight('admin:business:discount:code')) {
                    routeName = 'business-discounts-manager-show'
                }
                out.push({
                    route: { name: routeName },
                    icon: 'mdi-cash-register',
                    title: 'Gestion commerciale',
                    alert: false
                })
            }
            if (this.hasRight('admin:spa') && this.modules.spa) {
                out.push({
                    route: { name: 'spa-index' },
                    icon: 'mdi-spa',
                    title: 'Spa',
                    alert: false
                })
            }
            if (this.hasRight('admin:stats') && this.modules.stats) {
                out.push({
                    route: { name: 'stats-index' },
                    icon: 'mdi-chart-line',
                    title: 'Statistiques',
                    alert: false
                })
            }
            if (this.hasRight('admin:periodic') && this.modules.activities) {
                out.push({
                    route: { name: 'activities-index' },
                    icon: 'mdi-calendar-sync',
                    title: 'Activités périodiques',
                    alert: false
                })
            }
            if (this.hasRight('admin:kiosks') && this.modules.kiosks) {
                out.push({
                    route: { name: 'kiosks-index' },
                    icon: 'mdi-television',
                    title: 'Bornes',
                    alert: false
                })
            }
            if (this.hasRight('admin:lockers') && this.modules.lockers) {
                out.push({
                    route: { name: 'lockers-index' },
                    icon: 'mdi-locker-multiple',
                    title: 'Casiers',
                    alert: this.newIncidentsCounter
                })
            }
            if (this.hasRight('admin:quotations') && this.modules.quotations) {
                out.push({
                    route: { name: 'quotation-index' },
                    icon: 'mdi-file-sign',
                    title: 'Gestions des comptes pro',
                    alert: false
                })
            }
            if (this.hasRight('admin:params')) {
                out.push({
                    route: { name: 'setup' },
                    icon: 'mdi-cog',
                    title: 'Configuration',
                    alert: false
                })
            }

            return out
        }
    },
    methods: {
        logout () {
            this.$store.dispatch('logout').then(() => {
                this.wvLogout()
                this.$router.push({ name: 'login' })
            })
        },

        loadWebviewForcedRoute (route, opts = {}) {
            if (this.$route.fullPath !== route) {
                if (opts.forceReload) {
                    window.location.href = route
                } else {
                    this.$router.push(route).then(this.wvReady)
                }
            } else {
                // refresh
                window.location.reload()
            }
        },

        onUnreadCount (unread) {
            this.odeonPopup.unread = unread
        },

        ws_cb_ResponseAuth (res) {
            if (res.Success && this.version && res.Version !== this.version) {
                // Login successful check version
                this.$snotify.confirm('Une mise à jour de l\'interface Streamlor est disponible.', '', {
                    timeout: 0,
                    showProgressBar: false,
                    closeOnClick: false,

                    buttons: [
                        { text: 'Actualiser maintenant', action: () => window.location.reload(), bold: true },
                        { text: 'Plus tard', action: (toast) => { this.$snotify.remove(toast.id) } }
                    ]
                })
            } else {
                this.version = res.Version
            }
        },

        ws_cb_onNotify (payload) {
            EventBus.$emit(payload.Resource, payload.Event)
        },

        goToDims () {
            window.location.href = this.dimsBackofficeUrl + 'admin.php?dims_streamlortoken=' + this.authToken
        },

        onNewLockerIncident () {
            this.$store.dispatch('onNewLockerIncident')
        },

        goToVCR () {
            let urlVCR = ''
            if (this.vcrUrl.startsWith('http://') || this.vcrUrl.startsWith('https://')) {
                urlVCR = this.vcrUrl
            } else {
                urlVCR = 'http://' + this.vcrUrl
            }

            if (!urlVCR.endsWith('/')) {
                urlVCR += '/'
            }

            if (this.authUser && this.authUser.AccountID && this.authUser.ClientAccountID) {
                const infos = {
                    UserID: this.authUser.AccountID,
                    ClientID: this.authUser.ClientAccountID
                }
                ApiAuth.getAuthToken(infos).then(res => {
                    if (res.data && res.data.Token && res.data.Error === 0) {
                        urlVCR += '#/vendeur?authToken=' + res.data.Token
                        window.open(urlVCR, '_blank')
                    }
                })
            } else {
                window.open(urlVCR, '_blank')
            }
        }
    }
}
</script>

<style>
    .main-nav .v-list-item--active {
        border-right: 3px solid #00639D;
        /*color: #00639D !important;*/
    }
</style>
