import './common'
import axios from 'axios'

export default {
    getTurnstiles: () => axios.get('/ctm/turnstiles'),
    getPassings: (params) => axios.get('/ctm/passings', { params }),
    sendTurnstilePassing: (data) => axios.post('/ctm/passings', data),
    getPassingsExcel: (params) => axios.get('/ctm/passings', { params, responseType: 'blob' }),
    postGroups: (group) => axios.post('/ctm/groups', group),
    putGroups: (group, params = {}) => axios.put(`/ctm/groups/${group.ID}`, { Params: params, Data: group }), // Params : { updateConfig: false } (updateConfig for send config signal to turnstile electronic card)
    deleteGroups: (group) => axios.delete(`/ctm/groups/${group.ID}`, group),
    sendAccessControlGroupSignal: (data) => axios.post('/ctm/groups/send-signal', data)
}
